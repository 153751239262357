<template>
  <div class="row maz-base-component  maz-flex">
    <div class="col-10 px-0">

      <MazPhoneNumberInput v-if="!loading" v-model="TelOficina"
                           :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                           :preferred-countries="MazPhoneNumber.PaisesPreferidos"
                           showCodeOnList :size="size" noValidation
                           :loading="cargando" :placeholder="placeholder" />

    </div>
    <div class="col-2 px-0">
      <MazInput v-model="Extension" :size="size"
                :placeholder="placeholderExtension"
                autocomplete="" :loading="cargando">
      </MazInput>
    </div>
  </div>
</template>

<script>
  import Base from '@/api-base/base';
  import { MazPhoneNumberInput } from 'maz-ui'

  export default {
    name: 'Main-TelefonoExtension',
    components: {

      MazPhoneNumberInput,
    },
    props: {
      placeholderExtension: { type: String, default: 'Extensión' },
      placeholder: { type: String, default: 'Teléfono de oficina' },
      cargando: { type: Boolean, default: false },
      size: { type: String, default: null },
      value: { type: String, default: '' },
    },
    data() {
      return {
        loading: true,
        NoTelefono: '',
        TelOficina: '',
        TelOficinaTmp: '',
        Extension: '',
      };
    },
    watch: {
      value(valor) {
        console.debug(`Value=>${valor}`)
       // this.NoTelefono = valor;
        this.Value2Datos();
      },
      NoTelefono(valor) {
        console.debug(`NoTelefono=>${valor}`)
        //this.$emit('update:value', valor);
        this.$emit('input', valor);
        //  this.$set(this, 'value', valor);
        // this.value = valor;
      },
      TelOficina(newValue) {
        if (newValue == null || this.loading)
          return;
        console.debug(`TelOficina.newValue=>${newValue}`);
        var Ext = this.Extension;
        if (Ext != "") {
          this.NoTelefono = `${newValue}x${Ext}`;
        }
        else
          this.NoTelefono = newValue;
      },
      Extension(newValue) {
        if (newValue == null || this.loading)
          return;
        if (newValue != "") {
          this.NoTelefono = `${this.TelOficina}x${newValue}`;
        }
        else
          this.NoTelefono = this.TelOficina;
      }
    },
    mounted() {
      this.Value2Datos();
      /*
      console.debug(this.value);
      if (this.value != null)
        this.NoTelefono = this.value;*/
    },
    computed: {
      MazPhoneNumber() {
        return Base.MazPhoneNumber;
      },
      /*
      TelOficina: {
        get() {
          if (this.TelOficinaOld != null)
            return this.TelOficinaOld;

          console.debug(`TelOficina=>${this.NoTelefono}`);
          if (this.NoTelefono == null)
            return "";
          let substrings = this.NoTelefono.split('x');
          console.debug(`TelOficina=>${substrings[0]}`);
          return this.TelOficinaOld = substrings[0];
        },
        set(newValue) {
          if (newValue == null)
            return;
          this.TelOficinaOld = newValue;
          console.debug(`TelOficina.newValue=>${newValue}`);
          var Ext = this.TelOficinaExtension;
          if (Ext != "") {
            this.NoTelefono = `${newValue}x${Ext}`;
          }
          else
            this.NoTelefono = newValue;
        }
      },
      TelOficinaExtension: {
        get() {
          if ( this.NoTelefono == null)
            return "";
          let substrings = this.NoTelefono.split('x');
          if (substrings[1] == null)
            return "";
          return substrings[1];
        },
        set(newValue) {
          if (newValue == null)
            return;
          if (newValue != "") {
            this.NoTelefono = `${this.TelOficina}x${newValue}`;
          }
          else
            this.NoTelefono = this.TelOficina;
        }
      },*/
    },
    methods: {
      Value2Datos() {
        this.loading = true;
        if (this.value == null || this.value == "") {
          this.TelOficina = '';
          this.Extension = '';
          this.loading = false;
          return;
        }
        let substrings = this.value.split('x');
        console.debug(substrings);
        if (substrings[0] == null)
          this.TelOficina = "";
        else
          this.TelOficina = substrings[0];
        console.debug(this.TelOficina);
        this.TelOficinaTmp = this.TelOficina;
        if (substrings[1] == null)
          this.Extension = "";
        else
          this.Extension = substrings[1];
        this.loading = false;
      }
    }

  }
</script>

<style scoped>
  .country-selector {
    width: 5rem !important;
    max-width: 5rem !important;
    min-width: 5rem !important;
  }
</style>
