<template>
  <div>


    <ValidationObserver v-slot="{ handleSubmit}">
      <form @submit.prevent="handleSubmit(nuevo)">
        <div v-if="MostrarID">
          <ValidationProvider name="zohoSubscriptionID"
                              rules="required"
                              v-slot="{ passed, failed, errors }">
            <MazInput v-model="datos.zohoSubscriptionID"
                      placeholder="Llave de Suscripción"
                      :autocomplete="autocompleteSubscriptionID" :disabled="loading || cargando"
                      class="maz-mb-3" required :loading="loading || cargando" :size="size"
                      :error="errors.length>0"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <template v-slot:icon-left>
                <i class="fas fa-key"></i>
              </template>
            </MazInput>
          </ValidationProvider>
          <router-link :to="{  } " @click.native="MostrarID=false" class="d-block text-right">
            <i class="fas fa-heart-broken"></i> No cuento con ID de Suscripción
          </router-link>
        </div>
        <div v-else>
          <router-link :to="{  } " @click.native="MostrarID=true" class="d-block text-right mb-2">
            <i class="fas fa-heart"></i> Tengo ID de Suscripción
          </router-link>

          <ValidationProvider name="NoombreEmpresa"
                              rules="required"
                              v-slot="{ passed, failed, errors }">
            <MazInput v-model="datos.etiqueta"
                      placeholder="Nombre de la empresa"
                      :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                      class="maz-mb-3" required :loading="loading || cargando" :size="size"
                      :error="errors.length>0"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <template v-slot:icon-left>
                <i class="far fa-registered"></i>
              </template>
            </MazInput>
          </ValidationProvider>
          <div class="row mb-2 mx-0">
            <div class="col-4  mb-2 px-0">
              <MazInput v-model="datos.rfc"
                        placeholder="RFC / Tax ID" :size="size"
                        :disabled="loading || cargando" clearable
                        :loading="cargando" />
            </div>
            <div class="col-8  mb-2 px-0">
              <MazInput v-model="datos.razonSocial"
                        placeholder="Razon Social" :size="size"
                        :disabled="loading || cargando" clearable
                        :loading="cargando" />
            </div>
          </div>

          <MazInput v-model="datos.contacto" required class="mb-3"
                    placeholder="Contacto Principal" :size="size"
                    :disabled="loading || cargando"
                    :loading="cargando">
            <template v-slot:icon-left>
              <i class="fas fa-user-tie"></i>
            </template>
          </MazInput>

          <div class="row mb-2 mx-0">
            <div class="col-12 mb-2 px-3">
              <MainTelefonoExtension v-model="datos.telefono" size="sm" placeholderExtension="Ext." placeholder="Teléfono de oficina"
                                     :cargando="cargando" />
            </div>
          </div>
          <div class="row mb-2 mx-0">
            <div class="col-6  mb-2 px-0">
              <ValidationProvider name="NoEmpleados"
                                  rules="required|min_value:1|max_value:1000000"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.noSucursales" required
                          placeholder="# Sucursales" :size="size"
                          :disabled="loading || cargando"
                          :loading="cargando"
                          :error="errors>0" type="number"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <template v-slot:icon-left>
                    <i class="fas fa-building"></i>
                  </template>
                </MazInput>
              </ValidationProvider>
            </div>
            <div class="col-6  mb-2 px-0">
              <ValidationProvider name="NoEmpleados"
                                  rules="required|min_value:1|max_value:1000000"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.noEmpleados" required
                          placeholder="# Empleados" :size="size"
                          :disabled="loading || cargando"
                          :loading="cargando"
                          :error="errors>0" type="number"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <template v-slot:icon-left>
                    <i class="fas fa-address-book"></i>
                  </template>
                </MazInput>
              </ValidationProvider>
            </div>
          </div>
          <MazInput v-model="datos.paginaWeb"
                    placeholder="Página Web, ej. http://www.eClock.com.mx" :size="size" type="url"
                    :disabled="loading || cargando" clearable
                    :loading="cargando" />


          <collapse :multiple-active="false" :active-index="-1" class="mb-0">
            <collapse-item title="Capturar Dirección">
              <MainDireccionComp v-model="datos.dDireccion" size="sm"
                                 :cargando="cargando" />
            </collapse-item>
          </collapse>
        </div>
        <hr class="my-md-4" />
        <base-button v-if="!cargando" native-type="submit" slot="footer" type="primary" block>
          {{Etiqueta}}
        </base-button>



        <div class="text-muted text-center">

          <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
            {{ Erroneo }}
          </p>
          <div v-if="cargando" class="text-center mt-7">
            <b-spinner type="grow" label="cargando..."></b-spinner>
            Cargando...
          </div>

          <router-link v-if="Cancelar" :to="{  } " @click.native="Cerrar()" class="d-block mt-2">
            <i class="fas fa-chevron-left"> </i> Cancelar
          </router-link>
        </div>
      </form>
    </ValidationObserver>


  </div>
</template>

<script>
  import Referencias from '@/api-base/referencias.service';
  import Servicio from '@/api-base/suscripciones.service';
  import MainDireccionComp from '@/Vistas-base/Main/Direccion.comp.vue';
  import MainTelefonoExtension from '@/Vistas-base/Main/TelefonoExtension.comp.vue';
  import { Collapse, CollapseItem } from 'src/components';
  import Usuarios from '@/api-base/usuarios.service';
  import { extend } from "vee-validate";
  import {
    required,

    min_value,
    max_value
  } from "vee-validate/dist/rules";

  extend("required", required);

  extend("min_value", min_value);
  extend("max_value", max_value);

  export default {
    name: 'Suscripciones-Comp-Nuevo',
    components: {
      MainDireccionComp,
      Collapse,
      CollapseItem,
      MainTelefonoExtension
    },
    props: {
      //Muestrar cerrar
      Cancelar: { type: Boolean, default: false },
      Etiqueta: { type: String, default: 'Registrar Empresa' },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        size: 'sm',
        datos: {
          zohoSubscriptionID:'',
          referenciaID: null,
          dDireccion: { pais: 'MX' },
          contacto: '',
          telefono: '',
        },

        Modal: false,
        cargando: false,

        MostrarID: true,

        Erroneo: ''
      }
    },
    computed: {
      autocompleteSubscriptionID() {
        return `${this.$options.name}-SubscriptionID`;
      },
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },

    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      var Referencia = Referencias.getReferencia();
      if (Referencia != null && Number.isInteger(Referencia.id))
        this.datos.referenciaID = Referencia.id;

      Usuarios.getPerfilLocal((UsuarioPerfil) => {
        console.debug(UsuarioPerfil);
        if (this.datos.contacto == null || this.datos.contacto == '')
          this.datos.contacto = UsuarioPerfil.usuario.nombre;
        if (this.datos.telefono == null || this.datos.telefono == '') {

          this.datos.telefono = UsuarioPerfil.usuario.telefono;
        }

      });
    },
    created() {
    },
    methods: {
      nuevo() {
        Servicio.post(this, this.datos, (response) => {
          //Debe obtener nuevamente el perfil ya que se actualizo y ahora contiene la nueva suscripción
          Usuarios.getPerfilLocal(() => {
            this.$emit('guardado', response.data);
            this.Cerrar();
          }, true);
          
          /*
          console.debug(response.data);
          this.$emit('guardado', response.data);

          this.Cerrar();*/

        }, (MsgError, error) => {
            switch (error.response.status) {
              case 404:
                this.Erroneo = "Llave de suscripción no válido";
                return;
              case 409:
                this.Erroneo = "Ya se ha utilizado esa Llave en otra suscripción";
                return;
              case 402:
                this.Erroneo = "Plan Invalido para eClock GO";
                return;
            }
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        this.$emit('cancelar', {});
        this.datos = {
          dDireccion: { pais: 'MX' },
          contacto: '',
          telefono: '',
        };
        //this.Modal = false;

      },
    }
  }
</script>

<style scoped>
</style>
