<template>
  <div class="d-flex justify-content-center">

    <div class="col-md-7 col-xl-5  px-0">
      <AuthGrupoComp v-if="Paso=='Usuario'" @guardado="OnGuardadoUsuario" IniciarSesion="Ya tengo una cuenta" />

      <card v-else class=" card-login " headerClasses="px-0 mx-0 pt-0">
        <template slot="header" class="pb-2">
          <!-- <img class="card-img" src="img/card-primary.png" alt="Card image" />-->
          <h4 class="card-titulo  ">Registro</h4>
          <h4 v-if="Paso=='Suscripcion'" class="mx-3 text-muted">Ahora registra tu empresa y serás el <b>Administrador</b> más adelante podrás cambiar de opinión.</h4>
        </template>

        <template>

          <SuscripcionesNuevo v-if="Paso=='Suscripcion'" :Cancelar="false" class="mx-2" @guardado="OnGuardadoSuscripcion" />

        </template>

      </card>
    </div>

  </div>
</template>
<script>
  import Referencias from '@/api-base/referencias.service';
  import swal from 'sweetalert2';

  import AuthGrupoComp from './Auth.grupo.vue';
  import SuscripcionesNuevo from '@/Vistas-base/Suscripciones/Nuevo.comp.vue';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';

  export default {
    name: 'NuevaSuscripcion-Usuarios',
    components: {
      AuthGrupoComp,
      SuscripcionesNuevo
    },
    data() {
      return {
        // Paso: 'Usuario',Paso: 'Suscripcion',
        Paso: 'Usuario',

      };
    },
    computed: {


    },
    mounted() {
      // this.$router.replace("/");
    },
    methods: {
      OnGuardadoUsuario(Parametros) {
        //Asumirá que si no es nuevo quiere entrar a su suscripción creada previamente
        // Lo descubrid espues de validar varios usuarios
        if (Parametros.Estado != 'Nuevo') {
          //Preguntar si desea crear una nueva suscripción
          swal.fire({
            title: '¿Nuevo?',
            text: `¿Desea crear una nueva suscripción?`,
            icon: 'warning',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn  btn-danger ',
              cancelButton: 'btn '
            },
            confirmButtonText: 'Si, Nuevo',
            cancelButtonText: 'Cancelar',
            buttonsStyling: false
          }).then((resultado) => {
            console.debug(resultado);
            if (resultado.isConfirmed)
              this.Paso = 'Suscripcion';
            else
              this.$router.replace("/");
          });
          return;
        }
        this.Paso = 'Suscripcion';
      },
      OnGuardadoSuscripcion(Suscripcion) {
        //Redirigir a tutorial
        console.debug(Suscripcion);

        SuscripcionesUsuarios.setSuscripcionID(Suscripcion.suscripcionID);


        var Referencia = Referencias.getReferencia();
        if (Referencia != null && Number.isInteger(Referencia.id)) {
          //this.datos.referenciaID = Referencia.id;
          if (Referencia.tipoReferencia == 'NuevoDispositivo') {
            //Mostrar alta de dispositivo

          }
        }

        /*   swal.fire({
             title: 'Borrado',
             text: 'Se ha creado satisfactoriamente tu cuente, ahora puedes gozar de eClock Go.',
             icon: 'success',
             customClass: {
               confirmButton: 'btn btn-fill'
             },
             buttonsStyling: false
           });
           this.Actualiza();
         });*/

        this.$router.replace("/");
      },
    }
  };
</script>
<style lang="scss" scoped>
  .card-titulo {
    position: relative;
    font-size: 5em;
    font-weight: 900;
    /* color: black;*/
    margin-left: -5px;
    z-index: 1;
  }
</style>
